import React, { useState, useRef, useEffect } from 'react'
import impresora from '../images/impresora.png'
import factura from '../images/factura.png'
import camara from '../images/camara.png'
import verificacion from '../images/lista-de-verificacion.png'
import frame from '../images/frame.png'
import { useReactToPrint } from 'react-to-print';
import CryptoJS from 'crypto-js';
import { AddOrders, GetPerfil, GetCategorias, GetSkuList, GetProducts } from './dbfirebase'
import { useAuth } from "../context/authcontext";
import { useGeneral } from '../context/generalContext'
import { doc, collection } from "firebase/firestore";
import { db } from "../components/firebase";
import { ToastContainer, toast } from 'react-toastify';
import { storage } from "../components/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Autocomplete from 'react-google-autocomplete';
import check from '../images/check-48.png'
import QRGenerator from './QRGenerator';
import garantiaImage from '../images/GARANTIA.PNG';
import facturableImage from '../images/Facturable.PNG';
import ServicesPostventaLogo from '../images/ServicesPostventaLogo.PNG'
import upload from '../images/upload.png'
import { useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression';

export const NewOrder = () => {

    const [isChecked, setIsChecked] = useState(false);
    const [image, setImage] = useState()
    const [docimage, setDocImage] = useState()
    const [iddoc, setIdDoc] = useState()
    const [cliente, setCliente] = useState({
        Actualizado: [],
        Cliente: '',
        Domicilio_cli: '',
        CuitDni_cli: '',
        CodigoPostal_cli: '',
        Telefono_cli: '',
        Email_cli: '',
        Categoria: '',
        Articulo: '',
        Desperfecto: '',
        Comentarios: '',
        Garantia: false,
        Taller: '',
        SparePart:true,
        Tipo: '',
        Estado: 'Pendiente',
        Fecha: new Date().toLocaleString(),
        TimeStamp: new Date(),
        Repuestos: [],
        Mdo: '-',
        Firmar:'-',
        Num_Serie:'',
        Num_Os:'',
        PisoDept_cli:'-',
        Transporte_cli:'Retira',
        Latitud: '-',
        Longitud: '-',
    })

    const [facturacion, setFacturacion] = useState({
        NumFactura: '-',
        RazonSocialCli: '-',
        FechaCompra: '-',
    })
    const [place, setPlace] = useState('')
    const [sku, setSku] = useState()
    const [up, setUp] = useState(false)
    const [upp, setUpp] = useState(false)
    const OrderRef = useRef(null)
    const refrepuestos = useRef(null)
    
    const qrData = 'https://atencion.peabody.com.ar/'


    const [sended, setSended] = useState()
    const [send, setSend] = useState()
    const [selectedOption1, setSelectedOption1] = useState();
    const [selectedOption2, setSelectedOption2] = useState();
    const [selectedOption3, setSelectedOption3] = useState();
    const [selectedOption4, setSelectedOption4] = useState();

    const [descripcion1, setDescripcion1] = useState()
    const [descripcion2, setDescripcion2] = useState()
    const [descripcion3, setDescripcion3] = useState()
    const [descripcion4, setDescripcion4] = useState()

    const [date1, setDate1] = useState()
    const [date2, setDate2] = useState()
    const [date3, setDate3] = useState()
    const [date4, setDate4] = useState()

    const [userdatos, setUserdatos] = useState()
    const [categorias, setCategorias] = useState()

    const [downloadURL, setDownloadURL] = useState([]);
    const [downloadURLFac, setDownloadURLFac] = useState([]);
    const [secretKey, setSecretKey] = useState()
    const { userData, generateSecretKey } = useGeneral()
    const [nameExtencion, setNameExtencion] = useState()
    const [nameExtenciondoc, setNameExtenciondoc] = useState()
    const [mostrarBoton,setMostrarBoton] = useState(false)
    
    const ManoDeObraData = useSelector(state => state.manodeobra)    
    
    const perfil = async () => {
        const dato = await userData
        setUserdatos(dato)
    }
    
    // const costomasreciente = (data, subcategoria) =>{
    //     const fechaActual = Math.floor(new Date().getTime() / 1000);
    //     let fechaMasCercana = Number.MAX_SAFE_INTEGER;
    //     let campoMasCercano = null;
    //     try{
            
    //         Object.keys(data[0]).forEach((clave) => {
    //             if (clave !== 'Fecha') {
    //                 const [dia, mes, anio] = clave.split('/');
    //                 const fechaEnSegundos = new Date(anio, mes - 1, dia).getTime() / 1000;
    //                 const diferenciaAbsoluta = Math.abs(fechaActual - fechaEnSegundos);

    //                 // Actualiza el campo más cercano si la diferencia es menor
    //                 if (diferenciaAbsoluta < fechaMasCercana) {
    //                     fechaMasCercana = diferenciaAbsoluta;
    //                     campoMasCercano = clave;
    //                 }
    //             }})
    //         // Retorna los valores del campo más cercano
    //         const valoresCampoMasCercano = data[0][campoMasCercano];
    //         return valoresCampoMasCercano ?  Number(valoresCampoMasCercano[subcategoria]) : 0;
    //     }catch(error){
    //         console.log(error)
    //     }
    // }

    const costomasreciente = (data, subcategoria) => {
        const fechaActual = Math.floor(new Date().getTime() / 1000);
        let fechaMasCercana = Number.MAX_SAFE_INTEGER;
        let valoresCampoMasCercano = null;
        
        try {
            data.forEach((obj) => {
                Object.keys(obj).forEach((clave) => {
                    
                    if (clave !== 'Fecha') {
                        const [dia, mes, anio] = clave.split(/[/\-]/) // clave.split('/');
                        const fechaEnSegundos = new Date(anio, mes - 1, dia).getTime() / 1000;
                        
                        const diferenciaAbsoluta = Math.abs(fechaActual - fechaEnSegundos);
                        
                        // Actualiza los valores del campo más cercano si la diferencia es menor
                        if (diferenciaAbsoluta < fechaMasCercana) {
                            fechaMasCercana = diferenciaAbsoluta;
                            valoresCampoMasCercano = obj[clave];
                        }
                    }
                });
            });
            // Retorna los valores del campo más cercano
            return valoresCampoMasCercano ? Number(valoresCampoMasCercano[0][subcategoria]) : 0;
        } catch (error) {
            console.log(error);
            return '';
        }
    };
    
    // Función para manejar el cambio de estado del checkbox de numero de serie
    const handleCheckboxChange_disponible = (e) => { 
        if (e.target.checked){
            setCliente((prevState) => ({...prevState, ['Num_Serie']: 'NO DISPONIBLE'}))
            document.getElementById("Num_serie").value = 'NO DISPONIBLE'
        }else{
            document.getElementById("Num_serie").value = ''
        }
    };
    
    
    setTimeout(perfil, 1000)

    const handleForm1Change = (e) => {
        const { name, value } = e.target;
        // console.log(value)
        setCliente((prevState) => ({ ...prevState, 
            
            [name]: shouldConvertToLowerCase(name) ? value.toLowerCase() : value, //.toUpperCase(),
            Categoria:document.getElementById("categoria").value,
            Tipo:document.getElementById("tipo").value,
            Descripcion:document.getElementById("descripcion").value,
            ManoDeObra: costomasreciente(ManoDeObraData, document.getElementById("tipo").value) 
            
            }));
        // console.log(cliente.Transporte_cli)
    };
    

    const shouldConvertToLowerCase = (fieldName) => {
        const fieldsToLower = ['Email_cli']; // Agrega aquí los campos que quieres convertir a minúsculas
        return fieldsToLower.includes(fieldName);
    };

    const handleForm2Change = (e) => {
        const { name, value } = e.target;
        setFacturacion((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        setCliente((prevState) => ({ ...prevState, ['Garantia']: e.target.checked }))
    }


    const handleFormSubmit = async () => {
        const docRef = doc(collection(db, 'Ordenes'))
        
        setIdDoc(secretKey)

        // Crear una copia mutable de userdatos
        const updatedUserdatos = { ...userdatos };
        
        if (updatedUserdatos?.Permiso === 'Garante') {
            updatedUserdatos['Direccion'] = cliente.Domicilio_cli;
            updatedUserdatos['Localidad'] = cliente.Localidad_cli;
            updatedUserdatos['Provincia'] = cliente.Provincia_cli;
        }

        try {
            setSend(true)
            const ord = { ...cliente, ...updatedUserdatos, ...{ iddoc: secretKey }, ...facturacion }
            
            let campoVacio = "";
            if (Object.keys(ord).some(key => ord[key] === "") || place == '') {
                
                const campoVacioKey = Object.keys(ord).find(key => ord[key] === "");
                campoVacio = campoVacioKey;
                const inputElement = document.getElementById(campoVacio);
                if (inputElement) {
                    inputElement.style.border = "2px solid red";
                }
                
                toast.error(`Debe completar todos los campos de usuario`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                })
                setSend(false)
            } else {
                if (ord.Garantia){
                    
                    if (downloadURLFac.length > 0){ // Revisar esta validacion
                        await AddOrders(ord, docRef);
                        setSended(true)
                        toast.success('Datos cargados con exito', {
                            position: "top-left",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true
                        })
                    }else{
                        toast.error(`Error al subir la factura de compra`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true
                        })
                    }
                }else{
                    await AddOrders(ord, docRef);
                    setSended(true)
                    toast.success('Datos cargados con exito', {
                        position: "top-left",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true
                    })
                }
                
            }

        } catch (error) {
            console.log(error)
        }
    };

    //input para subir foto de producto
    const getimage = async(event) => {
        const imagen = event.target.files[0];
        
        // Opciones de compresion de archivo
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }

        try {
            const compressedFile = await imageCompression(imagen, options);  
            setImage(imagen);
        } catch (error) {
            console.log(error);
          }
        
        // Obtener el nombre del archivo y actualizar el estado
        const fileName = imagen.name;
        
        const fileExtension = fileName.split('.').pop().toLowerCase();
        setNameExtencion(fileExtension);
    };
    
    const getdocimage = async(event) => {
        const docimagen = event.target.files[0];

        // Opciones de compresion de archivo
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        // Bloqueamos el compresor porque esta dando error en las subidas.
        // try {
        //     const compressedFile = await imageCompression(docimagen, options);        
        //     setDocImage(compressedFile); // write your own logic
        //   } catch (error) {
        //     console.log(error);
        //   }

        setDocImage(docimagen);
        // Obtener el nombre del archivo y actualizar el estado
        const fileName = docimagen.name;
        const fileExtension = fileName.split('.').pop().toLowerCase();
        setNameExtenciondoc(fileExtension);
    };

    // const uploadimage = (image, name) => {
    //     if (image !== undefined) {
    //         const storageRef = ref(storage, name);
    //         const metadata = {
    //             contentType: 'image/jpeg',
    //         };
    //         uploadBytes(storageRef, image, metadata).then((snapshot) => {

    //         });
    //     }
    // }

    const uploadImage = (image, name) => {
        if (image && name) {
            // Obtener la extensión del archivo desde el nombre
            const fileExtension = name.split('.').pop().toLowerCase();
            
            // Lista de extensiones permitidas
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'jfif'];
    
            // Verificar si la extensión es permitida
            if (allowedExtensions.includes(fileExtension)) {
                const storageRef = ref(storage, name);
                const metadata = {
                    contentType: `image/${fileExtension}`,
                };
    
                uploadBytes(storageRef, image, metadata)
                    .then(async(snapshot) => {
                        // Obtener la URL de descarga de la imagen
                        const url = await getDownloadURL(storageRef);
                        setDownloadURL(prevURLs => [...prevURLs, url]);
            
                        // Limpiar la previsualización de la imagen
                        // setImagePreview('');
                        setImage('');
                        return true
                    })
                    .catch((error) => {
                        console.error('Error al subir la imagen:', error);
                    });
            } else {
                console.warn('Extensión de archivo no permitida. La imagen no se ha subido.');
            }
        } else {
            console.warn('Imagen o nombre de archivo no proporcionados. La imagen no se ha subido.');
        }
    };

    const uploadImageFactura = (image, name) => {
        if (image && name) {
            // Obtener la extensión del archivo desde el nombre
            const fileExtension = name.split('.').pop().toLowerCase();
            
            // Lista de extensiones permitidas
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'jfif'];
    
            // Verificar si la extensión es permitida
            if (allowedExtensions.includes(fileExtension)) {
                const storageRef = ref(storage, name);
                const metadata = {
                    contentType: `image/${fileExtension}`,
                };
    
                uploadBytes(storageRef, image, metadata)
                    .then(async(snapshot) => {
                        // Obtener la URL de descarga de la imagen
                        const url = await getDownloadURL(storageRef);
                        setDownloadURLFac(prevURLs => [...prevURLs, url]);
            
                        // Limpiar la previsualización de la imagen
                        // setImagePreview('');
                        setImage('');
                        return true
                    })
                    .catch((error) => {
                        console.error('Error al subir la imagen:', error);
                    });
            } else {
                console.warn('Extensión de archivo no permitida. La imagen no se ha subido.');
            }
        } else {
            console.warn('Imagen o nombre de archivo no proporcionados. La imagen no se ha subido.');
        }
    };


    //Funcion imprimir
    const handlePrint = useReactToPrint({
        content: () => OrderRef.current,
        // pageStyle: `
        // @page {
        //     size: 21cm 29.5cm; /* A4 landscape */
        //     margin: 0.3cm; /* Margen de 1cm en todos los lados */
        // }`,
    },
    );
    
    const getPageMargins = () => {
        return `@page { margin: ${'1rem'} ${'1rem'} ${'1rem'} ${'1rem'} !important; }`;
    };

    //datos categoria
    const categoria = async () => {
        const cat = await GetCategorias()
        const categ = cat.filter(objeto => Object.keys(objeto)[0] !== "Categoria" && Object.keys(objeto)[0] !== "sku")
        // console.log(cat)
        setCategorias(categ)
    }

    const GetSku = async() =>{
        const Skus = await GetSkuList()
        if (Skus){
            const products = Skus.sku;
            setSku(products);
        }
      }

    
    useEffect(() => {
        categoria()
        GetSku()
        setSecretKey(generateSecretKey('Ordenes'))
       
    }, [])

     // Obtener la fecha actual
    const today = new Date().toISOString().split('T')[0];

  // Obtener la fecha hace un año
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    const formattedOneYearAgo = oneYearAgo.toISOString().split('T')[0];

    const buscarValor = (arr, valor) => {
        for (let i = 0; i < arr.length; i++) {
            const obj = arr[i];
            for (const key in obj) {
                if (typeof obj[key] === 'object') {
                const resultado = buscarValor([obj[key]], valor);
                    if (resultado) {
                        return resultado;
                    }
                } else if (key === valor) {
                        return obj[key];
                    }
            }
        }
        return false;
    };

    const obj = {}
    categorias && categorias.map((item) =>{
        [item].map((i)=>{
            Object.assign(obj, i);
        })
    })
    
    const buscarCategoria = (objeto, claveBuscada, ruta = []) => {
        for (let nombreCategoria in objeto) {
            
            let categoria = objeto[nombreCategoria];

            if (typeof categoria === 'object') {
                if (claveBuscada in categoria) {
                    ruta.push(nombreCategoria);
                    ruta.push(claveBuscada);
                    return ruta;
                } else {
                    let resultado = buscarCategoria(categoria, claveBuscada, ruta.concat(nombreCategoria));
                    if (resultado !== false) {
                        let grupo = buscarClave(categorias,resultado)
                        return [resultado, grupo]
                    }
                }
            }
        }
        return false;
    }
    
    function buscarClave(obj, valorBuscado) {
        for (let i=0; i<=obj.length; i++){
            for (let clave in obj[i]) {
                if (clave === valorBuscado[0]) {
                    return obj[i].Grupo;
                }
            }
        }
        return null; // valor no encontrado
    }


    const handleKeyPress = (e, nextInputId) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInput = document.getElementById(nextInputId);
            if (nextInput && !nextInput.disabled) {
            nextInput.focus();
            }
        }
    };

    const listafallas = [
        {
            'No Enciende': 'No enciende a nivel visual ni funcional'
        },
        {
            'No Acciona': 'Enciende a nivel visual pero presenta un desperfecto funcional'
        },
        {
            'Operativo No Visible': 'Acciona a nivel funcional pero no enciende a nivel visual'
        },
        {
            'Rotura': 'Presenta una rotura o desgaste en el cuerpo o accesorio'
        },
        {
            'Codigo De Error': 'No acciona por causa de un código de error detallado'
        },
        {
            'Faltante': 'Presenta un faltante de accesorio o cuerpo de producto'
        },
        {
            'Derretido': 'Presenta signos de derretimiento en algún insumo o accesorio'
        },
        {
            'No conforme': 'Es funcional pero no cumple con las expectativas'
        },
        {
            'Daño Inducido': 'Presenta daños por uso inadecuado o externo'
        },
        {
            'A Normalizar': 'Inconveniente incategorizable que requiere detalles en las observaciones'
        }
    ]

    const asteriscoStyle = {
        color: 'red',
        fontSize: '1.2em', // Tamaño del asterisco
        fontWeight: 'bold', // Texto en negrita
        verticalAlign: 'super', // Alineación vertical para superíndice
      };

    const handleClickUploadImageFac = async() => {
        try{
            // Llama a uploadImage cuando se haga clic en el botón
            await uploadImageFactura(docimage, `factura_de_compras/${secretKey + "_" + facturacion.FechaCompra + "_" + facturacion.RazonSocialCli + "_" + facturacion.NumFactura}.${nameExtenciondoc}`);
            // console.log(secretKey)
            setUp(true)
            setMostrarBoton(true)
        }catch{
            
        }
    };

    const handleClickUploadImageImg = async() => {
        // Llama a uploadImage cuando se haga clic en el botón
        await uploadImage(image, `ProductoOrdenes/${secretKey + "_" + cliente.CuitDni_cli}.${nameExtencion}`);
        // console.log(secretKey)
        setUpp(true)
    };

 
    return (
        
        <div style={{ marginBottom: '10px', marginTop: '10px'}} ref={OrderRef}>
            {/* <style>{getPageMargins()}</style> */}
            <ToastContainer />
            <div className='container' >
                <div className='row'>
                    <div className='col-6 d-flex justify-content-start d-none d-print-block'> 
                        <img src={ServicesPostventaLogo} width="210" height="60"></img>
                    </div>
                    <div className='col-6 d-flex justify-content-end d-none d-print-block'>
                        <p className='d-flex justify-content-end'>Fecha: {new Date().toLocaleString().split(',')[0]}</p>
                    {/* Titulo y ID documento */}
                    </div>
                </div>
                <div className="row" style={{ marginBottom: '15px' }}>
                    <div className='col-6 d-flex justify-content-start'>
                        <h3>Orden de servicio</h3>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <p>ID: {iddoc ? iddoc : 'Documento sin registrar'}</p>
                    </div>
                </div>
                {/* Datos taller y boton imprimir */}
                <div style={{ borderBottom: '1px solid', width: '100%', marginBottom: '20px', borderBottomColor: '#bcbcbc' }}>
                    <div className="row">
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Responsable: {userdatos.Titular}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Razon social: {userdatos.Taller}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Domicilio: {userdatos.Direccion}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Telefono: {userdatos.Telefono}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>E-mail: {userdatos.Email}</p>}
                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Cuit: {userdatos.Cuit}</p>}
                    </div>
                    <div className="row" style={{ marginBottom: '20px' }}>
                        <div className='col-12 d-flex justify-content-end d-print-none'>
                        {sended && 
                            <button style={{ border: 'none', backgroundColor: '#ffffff' }} onClick={() => { handlePrint() }}>
                                <label style={{ margin: '0 20px' }}>Imprimir</label>
                                <img src={impresora} width='50rem' height='50rem' alt='' />
                            </button >
                        }   
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-12 d-flex justify-content-start' style={{ marginBottom: '20px' }}>
                        <h4>Datos del cliente:</h4>
                    </div>

                    <form className= 'd-flex justify-content-center' style={{ marginBottom: '20px' }}>
                        <div className="row" style={{ borderBottom: '1px solid', width: '100%', borderBottomColor: '#bcbcbc' }}>

                        
                            <div className='col-lg-6  ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Nombre<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Cliente"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Nombre completo"
                                    id="Cliente"
                                    autoFocus
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem', border: cliente.Cliente.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                />
                            </div>


                            <div className='col-lg-6  ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Domicilio<span style={asteriscoStyle}>*</span>:</label>
                                <Autocomplete
                                    name='Domicilio_cli'
                                    className='form-control'
                                    style={{ maxWidth: '20rem', border: cliente.Domicilio_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    //onChange={handleForm1Change}
                                    placeholder='Calle, localidad, provincia'
                                    id='domicilio'
                                    disabled={sended ? true : false}
                                    apiKey={process.env.REACT_APP_MAP} // Reemplazar con la API key de Google Places
                                    onPlaceSelected={(place, autocomplete) => {
                                        // Obtén la dirección del lugar seleccionado y actualiza el estado 'taller'
                                        const addressComponents = place?.address_components || [];
                                        let direccion = '';
                                        let localidad = '';
                                        let provincia = '';
                                        let latitud = '';
                                        let longitud = '';

                                        addressComponents.forEach((component) => {
                                            if (component.types.includes('street_number') || component.types.includes('route')) {
                                                direccion += component.long_name + ' ';
                                            }
                                            if (component.types.includes('locality')) {
                                                localidad = component.long_name;
                                            }
                                            if (component.types.includes('administrative_area_level_1')) {
                                                provincia = component.long_name;
                                            }
                                            // Puedes agregar más lógica aquí para otros componentes de dirección si es necesario
                                        });

                                        // Obtener latitud y longitud del lugar seleccionado
                                        latitud = place.geometry.location.lat();
                                        longitud = place.geometry.location.lng();
                                        
                                        // Actualiza el estado 'taller'
                                        setCliente((prevCliente) => ({
                                            ...prevCliente,
                                            Domicilio_cli: direccion.trim(),
                                            Localidad_cli: localidad,
                                            Provincia_cli: provincia,
                                            Latitud: latitud,
                                            Longitud: longitud,
                                        }));
                                        setPlace(place)
                                    }}
                                    options={{
                                        types: ['address'],
                                        componentRestrictions: { country: 'ar' },
                                        // fields: ['postal_code']
                                    }}
                                />
                            </div>

                            
                            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Cuit/Dni<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="CuitDni_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Sin guiones ni puntos"
                                    id="CuitDni_cli"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem',border: cliente.CuitDni_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                    maxLength="15"
                                />
                            </div>
                            
                            <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Telefono<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Telefono_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Sin guiones ni puntos"
                                    id="Telefono_cli"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem', border: cliente.Telefono_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                    maxLength="30"
                                />
                            </div>

                            
                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>E-mail<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="email"
                                    name="Email_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="ejemplo@ejemplo.com.ar"
                                    id="Email_cli"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem', border: cliente.Email_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                />
                            </div>

                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Localidad:</label>
                                <input 
                                    type = "text"
                                    name = "Localidad_cli"
                                    className = "form-control"
                                    placeholder = "Localidad"
                                    id = "Localidad_cli"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem', border: cliente.Localidad_cli !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    defaultValue={place && place.address_components && place.address_components[2] && place.address_components[2].long_name ? place.address_components[2].long_name : ''}
                                    disabled = {place ? false : true}
                                />  
                            </div>

                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Provincia:</label>
                                <input 
                                    type = "text"
                                    name = "Provincia_cli"
                                    className = "form-control"
                                    placeholder = "Provincia"
                                    id = "Provincia_cli"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem',border: cliente.Provincia_cli !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    defaultValue={place && place.address_components && place.address_components[4] && place.address_components[4].long_name ? place.address_components[4].long_name : ''}
                                    disabled = {place ? false : true}
                                />  
                            </div>

                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>C. Postal<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="CodigoPostal_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Codigo postal"
                                    id="CodigoPostal_cli"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem', border: cliente.CodigoPostal_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                />
                            </div>

                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Piso/Dept<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="PisoDept_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="PisoDept"
                                    id="PisoDept_cli"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem', border: cliente.PisoDept_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                />
                            </div>

                            {/* <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Transporte<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Transporte_cli"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Transporte"
                                    id="Transporte_cli"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem', border: cliente.Transporte_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                />
                            </div> */}
                            {/* <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Transporte<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Transporte_cli"
                                    list="transporte-options"
                                    className="form-control"
                                    placeholder="Transporte"
                                    id="Transporte_cli"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem', border: cliente.Transporte_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                />
                                <datalist id="transporte-options">
                                    <option value="Retira">Retira</option>
                                    <option value="Correo Argentino">Correo Argentino</option>
                                    <option value="Credifin">Credifin</option>
                                    <option value="Cadeteria">Cadeteria</option>
                                    <option value="Otros">Otros</option>
                                </datalist>
                            </div> */}
                            <div className='col-lg-6 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>
                                    Transporte<span style={asteriscoStyle}>*</span>:
                                </label>
                                <select
                                    name="Transporte_cli"
                                    id="Transporte_cli"
                                    className="form-control"
                                    onChange={handleForm1Change}
                                    style={{ maxWidth: '20rem', border: cliente.Transporte_cli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={sended ? true : false}
                                    // value={cliente.Transporte_cli}
                                >
                                    <option value="" disabled>Seleccione una opción</option>
                                    <option value="Retira">Retira</option>
                                    <option value="Correo Argentino">Correo Argentino</option>
                                    <option value="Credifin">Credifin</option>
                                    <option value="Cadeteria">Cadeteria</option>
                                    <option value="Otros">Otros</option>
                                </select>
                            </div>


                        </div>
                    </form>
                </div>
               
                
               
                <div className="row">                                                                    
                    <div className='col-6 d-flex justify-content-start'>
                        <h4>Datos del producto:</h4>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                    {isChecked? <img className="imagen" src={garantiaImage} alt="garantia"/>:
                                    <img className="imagen" src={facturableImage} alt="facturable"/>}
                    </div>
                    
                    <div className='col-lg-6 d-flex ms-1 mb-4 form-switch' style={{ alignItems: 'center' }}>
                        <label style={{ margin: '0 1rem' }}>¿En garantía?: </label>
                        <input className="form-check-input"
                            type="checkbox"
                            value=""
                            name="Garantia"
                            id="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            disabled={sended ? true : false}
                            style={{margin:'initial', border: cliente.Garantia !== '' ? '1px solid green' : '1px solid #bcbcbc' }} />
                            
                    </div>

                    {isChecked && <div className="row">
                        <div className='col-lg-3 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center contenedor'>
                            <label style={{ margin: '0 1rem', maxWidth: '10rem', minWidth: '1rem' }}>N° factura<span style={asteriscoStyle}>*</span>:</label>
                            <input type="text"
                                name="NumFactura"
                                className="form-control"
                                placeholder="Nº Factura"
                                id="NumFactura"
                                onChange={handleForm2Change}
                                style={{ minWidth: '8rem',border: facturacion.NumFactura.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                disabled={sended ? true : false}
                            />
                        </div>
                        <div className='col-lg-3 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center contenedor'>
                            <label style={{ margin: '0 1rem', maxWidth: '10rem', minWidth: '1rem' }}>Razon Social<span style={asteriscoStyle}>*</span>:</label>
                            <input type="text"
                                name="RazonSocialCli"
                                className="form-control"
                                placeholder="Razon Social"
                                id="RazonSocialCli"
                                onChange={handleForm2Change}
                                style={{ minWidth: '8rem', border: facturacion.RazonSocialCli.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                disabled={sended ? true : false}
                            />
                        </div>
                        <div className='col-lg-3 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center contenedor'>
                            <label style={{ margin: '0 1rem', maxWidth: '10rem', minWidth: '1rem' }}>Fecha<span style={asteriscoStyle}>*</span>:</label>
                            <input type="date"
                                name="FechaCompra"
                                className="form-control"
                                placeholder="Fecha de compra"
                                id="FechaCompra"
                                onChange={handleForm2Change}
                                style={{ minWidth: '8rem', border: facturacion.FechaCompra.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                disabled={sended ? true : false}
                                max={today}
                                min={formattedOneYearAgo}
                            />
                        </div>
                        {facturacion.NumFactura !== '-' && facturacion.RazonSocialCli !== '-' && facturacion.FechaCompra !== '-' &&
                        <div className='col-sm-3 d-flex justify-content-end image-upload d-print-none' style={{ marginBottom: '20px' }}>
                            <label htmlFor='photodoc'>Adj. fac. compra (*jpg, *jpeg, *png, *jfif)
                                <img src={docimage?check:factura} width='55rem' height='55rem' alt='a' style={{ margin: '0 0 0 20px' }}  />
                            </label>
                            <input  type="file" accept="image/jpg" capture id='photodoc' onChange={getdocimage}></input>
                            <button style={{ margin: '30px auto 0', border: 'none', backgroundColor: '#ffffff' }} 
                                    onClick={() => handleClickUploadImageFac()}>
                                    <div className='container d-flex justify-content-end'>
                                        {docimage? 
                                            <label>
                                                <img src={up? check: upload} width="50rem" height="50rem" alt='b' />
                                                Confirmar archivo
                                            </label>
                                            :""
                                        }
                                    </div>
                            </button>
                        </div>}
                    </div>
                    }

                    {!isChecked || mostrarBoton?
                    <form className= 'd-flex justify-content-center'style={{ marginBottom: '20px' }}>
                        <div className="row" style={{ borderBottom: '1px solid', width: '100%', borderBottomColor: '#bcbcbc' }}>

                        <div className='col-lg-3 d-flex ms-auto mb-3 flex-column align-items-center contenedor'>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '1rem' }}>Articulo<span style={asteriscoStyle}>*</span>:</label>
                                <input type = "text"
                                    name = "Articulo"
                                    //onChange = {handlechange} 
                                    className = "form-control"
                                    placeholder = "Articulo"
                                    id = "articulo"
                                    list="Articulo"
                                    autoComplete="off"
                                    onChange={(e) => { e.target.value = e.target.value.toUpperCase(); handleForm1Change(e); }}
                                    style={{minWidth:'8rem'}}
                                    onKeyPress={(e) => handleKeyPress(e, "falla")}
                                    />
                                    {sku && 
                                    <datalist id="Articulo">
                                        {sku.map(art => (
                                            <option key={art} value={art}>
                                            {art}
                                            </option>
                                        ))}
                                    </datalist>}
                                {/* <select
                                    name="Articulo"
                                    className="form-select"
                                    id="Articulo"
                                    autoComplete="off"
                                    onChange={handleForm1Change}
                                    style={{ minWidth: '8rem',border: cliente.Articulo.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={!cliente.Tipo || sended}
                                >
                                    <option value="">Selecciona un tipo</option>
                                    {cliente.Tipo &&
                                        categorias
                                            .filter((categoria) => categoria.Grupo === cliente.Categoria)
                                            .map((producto) =>(
                                                Object.keys(producto[`${cliente.Tipo}`][0]).map((codigo) =>(
                                                        <option key={codigo} value={codigo}>
                                                            {codigo}
                                                        </option>
                                                ))
                                            ))}
                                </select> */}
                                {/* <input type="text"
                                    name="Articulo"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Articulo"
                                    id="Articulo"
                                    list="Articulos"
                                    autoComplete="off"
                                    onChange={handleForm1Change}
                                    style={{ minWidth: '8rem',border: cliente.Articulo.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={!cliente.Tipo || sended}
                                />
                                <datalist id="Articulos">
                                    {cliente.Tipo && categorias.filter((categoria) => (
                                        categoria.Grupo === cliente.Categoria)).map((producto) => (
                                            Object.keys(producto[`${cliente.Tipo}`][0]).map((codigo) => (
                                                <option key={codigo} value={codigo} />
                                            ))))}
                                </datalist> */}
                            </div>

                            <div className='col-lg-3 d-flex ms-auto mb-3 flex-column align-items-center contenedor'>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '1rem' }}>Categoria<span style={asteriscoStyle}>*</span>:</label>
                                <input type = "text"
                                    name = "Categoria"
                                    className = "form-control"
                                    placeholder = "Categorias"
                                    id = "categoria"
                                    list="Categoria"
                                    autoComplete="off"
                                    onChange={handleForm1Change}
                                    style={{minWidth:'8rem'}}
                                    disabled={true}
                                    value={cliente.Articulo && buscarCategoria(obj, cliente.Articulo)[1]? buscarCategoria(obj, cliente.Articulo)[1]:'Buscando...' || ''}
                                />
                                {/* <select
                                    name="Categoria"
                                    className="form-select"
                                    id="Categoria"
                                    autoComplete="off"
                                    onChange={handleForm1Change}
                                    style={{ minWidth: '8rem',border: cliente.Categoria.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={cliente.Tipo}
                                >
                                    <option value="">Selecciona una categoría</option>
                                    {categorias &&
                                        categorias.map((categoria) => (
                                            <option key={categoria.Grupo} value={categoria.Grupo}>
                                                {categoria.Grupo}
                                            </option>
                                        ))}
                                </select> */}
                                
                            </div>
                            <div className='col-lg-3 d-flex ms-auto mb-3 flex-column align-items-center contenedor'>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '1rem' }}>Tipo<span style={asteriscoStyle}>*</span>:</label>
                                <input type = "text"
                                    name = "Tipo"
                                    className = "form-control"
                                    placeholder = "Tipo"
                                    id = "tipo"
                                    list="Tipo"
                                    autoComplete="off"
                                    onChange={handleForm1Change}
                                    style={{minWidth:'8rem'}}
                                    disabled={true}
                                    value={cliente.Articulo && buscarCategoria(obj, cliente.Articulo)[0]? buscarCategoria(obj, cliente.Articulo)[0][0]:'Buscando...' || ''}
                                    />
                                {/* <select
                                    name="Tipo"
                                    className="form-select"
                                    id="Tipo"
                                    autoComplete="off"
                                    onChange={handleForm1Change}
                                    style={{ minWidth: '8rem',border: cliente.Tipo.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc' }}
                                    disabled={!cliente.Categoria || cliente.Articulo}
                                >
                                    <option value="">Selecciona un tipo</option>
                                    {cliente.Categoria &&
                                        categorias
                                            .filter((categoria) => categoria.Grupo === cliente.Categoria)
                                            .map((producto) => {
                                                const nuevoProducto = {};
                                                for (const key in producto) {
                                                    if (key !== "Grupo") {
                                                        nuevoProducto[key] = producto[key];
                                                    }
                                                }
                                                return Object.keys(nuevoProducto).map((art) => (
                                                    <option key={art} value={art}>
                                                        {art}
                                                    </option>
                                                ));
                                            })}
                                </select> */}
                            </div>

                            
                            
                            <div className='col-lg-3 d-flex ms-auto mb-3 flex-column align-items-center contenedor'>
                                <label style={{ margin: '0 1rem', maxWidth: '15rem', minWidth: '1rem' }}>Descripcion<span style={asteriscoStyle}>*</span>:</label>
                                <input 
                                    type = "text"
                                    name = "Descripcion"
                                    //onChange = {handlechange} 
                                    className = "form-control"
                                    placeholder = "Descripcion"
                                    id = "descripcion"
                                        
                                    onChange={handleForm1Change}
                                    style={{minWidth:'8rem'}}
                                    disabled={true}
                                    value={categorias && buscarValor(categorias, cliente.Articulo)? buscarValor(categorias, cliente.Articulo):'Buscando...' || ''}
                                    />
                                {/* <input
                                    type="text"
                                    name="Descripcion"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Descripcion"
                                    id="Descripcion"

                                    onChange={handleForm1Change}
                                    style={{ minWidth: '8rem' }}
                                    disabled={true}
                                    value={cliente.Articulo && categorias.filter((categoria) => (
                                        categoria.Grupo === cliente.Categoria)).map((producto) => (
                                            producto[`${cliente.Tipo}`][0][`${cliente.Articulo}`]
                                        ))}
                                /> */}
                            </div>
                            {/* <div className= 'row'> */}
                                <div className= 'row'>
                                    <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                        <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Numero de serie<span style={asteriscoStyle}>*</span>:</label>
                                        <input
                                            type="text"
                                            name="Num_Serie"
                                            //onChange = {handlechange} 
                                            className="form-control"
                                            placeholder="Numero de serie"
                                            id="Num_serie"
                                            style={{minWidth: '8rem', border: cliente.Num_Serie.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc'}}
                                            disabled={!cliente.Articulo || sended}
                                            onChange={handleForm1Change}
                                        // disabled={sended? true:false}
                                        />
                                    <div className='col-lg-6 d-flex' style={{ alignItems: 'center' }}>
                                        <label style={{ margin: '0 1rem' }}>NO DISPONIBLE: </label>
                                        <input className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            // name="Garantia"
                                            id="checkbox_nodisponible"
                                            // checked={isChecked}
                                            onChange={handleCheckboxChange_disponible}
                                            disabled={sended ? true : false}
                                            style={{margin:'initial', border: cliente.Garantia !== '' ? '1px solid green' : '1px solid #bcbcbc' }} 
                                        />
                                    </div>
                                </div>    
                                </div>
                                <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                    <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Orden de servicio<span style={asteriscoStyle}>*</span>:</label>
                                    <input
                                        type="text"
                                        name="Num_Os"
                                        //onChange = {handlechange} 
                                        className="form-control"
                                        placeholder="Numero de orden"
                                        id="Num_Os"
                                        style={{minWidth:'8rem', border: cliente.Num_Os.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc'}}
                                        disabled={!cliente.Articulo || sended}
                                        onChange={handleForm1Change}
                                    // disabled={sended? true:false}
                                    />
                                </div>
                            {/* </div> */}
                            {/* <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Falla:</label>
                                <input
                                    type="text"
                                    name="Desperfecto"
                                    //onChange = {handlechange} 
                                    className="form-control"
                                    placeholder="Descripcion de la falla del producto"
                                    id="falla"
                                    list='Falla'
                                    //autoComplete="off"
                                    disabled={!cliente.Articulo || sended}
                                    onChange={handleForm1Change}
                                // disabled={sended? true:false}
                                />
                                <datalist id="Falla">
                                    {listafallas.map((fallas) => (
                                        Object.keys(fallas).map((key) =>
                                            <option key={key} value={key}>
                                                {fallas[key]}
                                            </option>)
                                    ))}
                                </datalist>
                            </div> */}
                            <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Mtv. devolucion<span style={asteriscoStyle}>*</span>:</label>
                                <select
                                    name="Desperfecto"
                                    className="form-control"
                                    placeholder="Motivo de la devolucion"
                                    id="Desperfecto"
                                    style={{minWidth:'8rem', border: cliente.Desperfecto.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc'}}
                                    onChange={handleForm1Change}
                                    disabled={!cliente.Articulo || sended}
                                >
                                    <option value="">Selecciona una falla</option> {/* Opción vacía para deseleccionar */}
                                    {listafallas.map((fallas) => (
                                    Object.keys(fallas).map((key) => (
                                        <option key={key} value={key}>
                                        {fallas[key]}
                                        </option>
                                    ))
                                    ))}
                                </select>
                            </div>
                        {cliente.Desperfecto && 
                            <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Falla declarada<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Desperfecto_declarado"
                                    className="form-control"
                                    placeholder="Descripcion de la falla del producto"
                                    id="Desperfecto_declarado"
                                    style={{minWidth:'8rem', border: cliente.Desperfecto_declarado !== '' ? '1px solid green' : '1px solid #bcbcbc'}}
                                    onChange={handleForm1Change}
                                    disabled={!cliente.Articulo || sended}
                                    
                                />
                            </div>
                        }
                            <div className='col-lg-12 d-flex mb-3 contenedor' style={{ alignItems: 'center' }}>
                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Observacion<span style={asteriscoStyle}>*</span>:</label>
                                <input
                                    type="text"
                                    name="Comentarios"
                                    style={{minWidth:'8rem', border: cliente.Comentarios.trim() !== '' ? '1px solid green' : '1px solid #bcbcbc'}} 
                                    className="form-control"
                                    placeholder="Estado general del producto. ej. Marcas, Sin accesorios, en caja original...etc."
                                    id="Comentarios"
                                    autoComplete="off"
                                    disabled={!cliente.Articulo || sended}
                                    onChange={handleForm1Change}
                                // disabled={sended? true:false}
                                />
                            </div>
                        </div>
                    </form>:
                    <div>
                        <p className='col-12 d-flex justify-content-center'>Debe confirmar la factura de compra</p>
                    </div>
                    }
                    <div className="row">
                        {mostrarBoton && 
                        <>
                        <div className='col-12 d-flex justify-content-start d-print-none'>
                            {/* <h4>Datos del servicio:</h4> */}
                        </div>
                        <div className='col-12 d-flex justify-content-end image-upload d-print-none' style={{ marginBottom: '20px' }}>
                            
                            <label  className = 'd-print-none' htmlFor='photo'>Adjuntar fotos (*jpg):
                                <img src={image ? check:camara} width='50rem' height='50rem' alt='' style={{ margin: '0 0 0 20px' }} />
                            </label>
                            <input type="file" accept="image/jpg" id='photo' onChange={getimage} capture='environment'></input>
                            <button style={{border: 'none', backgroundColor: '#ffffff' }} 
                                    onClick={() => handleClickUploadImageImg()}>
                                    <div className='container d-flex justify-content-center'>
                                        <img className="col" src={upp? check:upload} width="50rem" height="50rem" alt='' />
                                    </div>
                            </button>
                        </div>
                        </>
                        }
                        
                        <div className='card-body col-12 d-flex justify-content-start d-print-none' style={{ overflowX: "auto", marginBottom: '20px' }}>
                            
                        </div>
                     { mostrarBoton &&  isChecked && docimage && up?
                        <div className='col-12 d-flex justify-content-center d-print-none' style={{ marginBottom: '20px' }}>
                            <button type="submit" onClick={handleFormSubmit} className='col-12 btn btn-outline-success' disabled={send ? true : false} >Guardar</button>
                        </div>:
                        !isChecked?
                        <div className='col-12 d-flex justify-content-center d-print-none' style={{ marginBottom: '20px' }}>
                            <button type="submit" onClick={handleFormSubmit} className='col-12 btn btn-outline-success' disabled={send ? true : false} >Guardar</button>
                        </div>:''
                    }
                        {/* <div className='col-12 d-flex justify-content-end image-upload d-print-none'style={{marginBottom:'20px'}}>
                            <label htmlFor='photo'>Adjuntar conformidad:    
                                <img src={verificacion} width='50rem' height='50rem' alt='' style={{margin:'0 0 0 20px'}}/>
                            </label>
                            <input type="file" accept="image/*" capture id='photo' onChange={getimage}></input>
                    </div> */}
                        <div className='row'>
                            <div className='col-6 d-none d-print-block' style={{ marginTop: '150px' }}>
                                <label className='d-flex justify-content-center'>......................................................................</label>
                                <label className='d-flex justify-content-center'>Firma</label>
                            </div>
                            {/* QR para acceder a la app del cliente */}
                            <div className='col-6 d-none d-print-block' style={{marginTop:'20px'}}>
                                <p className='d-flex justify-content-end' style={{margin:'0 0 0 0'}}>¡Hace el seguimiento desde la web!</p>
                                <div className='d-flex justify-content-end' style={{margin:'0 4.5rem 0 0'}} >
                                    <QRGenerator data={qrData}/>
                                </div>
                                <p className='d-flex justify-content-end'style={{margin:'0 0 0 0'}}>https://atencion.peabody.com.ar/</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}